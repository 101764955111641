@media screen and (min-width: 320px) and (max-width: 480px) {
    .card {
        width: 850px;
        height: 493px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .basic-info p {
        margin: 0;
        font-size: 32px;
    }

    .basic-info p:first-child {
        font-size: 40px;
    }

    .socials {
        display: flex;
        width: 325px;
        height: 75px;
        align-items: center;
        justify-content: space-evenly;
    }

    .bio {
        color: white;
        text-align: center;
        width: 375px;
        position: relative;
    }
    
    .bio p {
        margin: 0;
        font-size: 14px;
        height: 260px;
        padding: 18px;
    }
    
    .bio p:first-child {
        font-size: 24px;
        margin: 44px 0 0 0;
        height: 34px;
    }

    .languages {
        width: 325px;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    @media (orientation: portrait) {
        .small-button {
            width: 23px;
            height: 23px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: black;
            position: absolute;
            bottom: 12px;
        }
    }

    @media screen and (orientation: landscape) {
        .small-button {
            width: 23px;
            height: 23px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: black;
        }
    }

    .portfolio {
        color: white;
        text-align: center;
        width: 375px;
        position: relative;
    }

    .portfolio p {
        font-size: 56px;
        margin: 22px 0 22px 0;
        height: 75px;
    }
    
    .portfolio-items {
        height: 207px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin: 0 0 91px 0;
    }
    
    .big-button {
        display: flex;
        width: 100px;
        height: 100px;
        align-items: center;
        justify-content: center;
        background-color: black;
        position: relative;
    }

    .big-button img {
        width: 80%;
        height: 80%;
    }

    .dst-mods {
        color: white;
        text-align: center;
        width: 375px;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
    }
    
    .dst-mods p:first-child {
        font-size: 30px;
        margin: 12px 0 12px 0;
    }
    
    .dst-mods p {
        font-size: 24px;
        margin: 12px 0 12px 0;
    }
    
    .dst-mods-items {
        display: flex;
        width: 250px;
        height: 100px;
        align-items: center;
        justify-content: space-evenly;
    }
    
    .slider {
        width: 350px;
        height: 175px;
        background-color: #000000;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .slider.portal.zoomed {
        display: none;
    }

    .error {
        width: 350px;
        height: 350px;
        background-color: #000000;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .error p {
        margin: 0;
    }
}