* {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: lighter;
}

body {
    background-color: #292B39;
}

#root {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

    
.card div, a {
    color: white;
    text-align: center;

    -webkit-animation: fadein 2s;
    -moz-animation: fadein 2s;
    -o-animation: fadein 2s;
    animation: fadein 2s;
}

.particles-background {
    z-index: -1;
    position: absolute;
}

.rounded {
    border-radius: 100%;
}

.avatar-container {
    width: 200px;
    height: 200px;
    margin: 46px 0 0 0;
}

.avatar {
    width: 200px;
    height: 200px;
    object-fit: none;
    object-position: 50% 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.status {
    width: 81px;
    height: 81px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    top: -200px;
    left: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.status img {
    width: 55px;
}

.basic-info {
    height: 98px;
    color: white;
    text-align: center;
}

.button {
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    background-color: black;
    position: relative;
}

.clickable {
    transition: background-color 0.8s;
}

.clickable:hover, .clickable:active {
    cursor: pointer;
    background-color: #313552 !important;
}

.clicked {
    background-color: #313552 !important;
}

.image-picker-bar {
    width: 100%;
    height: 15%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 85%;
}

.image-picker {
    width: 20px;
    height: 10px;
    background-color: black;
    border-radius: 5px;
    margin: 6px;
}

.pricing {
    width: 300px;
    margin: 0 0 6px 0;
}

.pricing tr td:first-child {
    text-align: left;
}

.pricing tr td {
    text-align: right;
}

.tooltip {
    position: absolute;
    display: flex;
    height: 25px !important;
    background-color: #313552;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.8s, visibility 0.8s;
    top: 110%;
    z-index: 1;

    -webkit-animation: fadein 0s !important;
    -moz-animation: fadein 0s !important;
    -o-animation: fadein 0s !important;
    animation: fadein 0s !important;
}

.tooltip-under:hover > .tooltip, .tooltip-under:active > .tooltip {
    opacity: 1;
    visibility: visible;
}

.tooltip p {
    height: 20px !important;
    font-size: 13px !important;
    margin: 0 !important;
    font-weight: lighter;
    padding: 10px;
    white-space: nowrap;
}

.tooltip:hover, .tooltip:active {
    cursor: default;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-moz-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.slider .inner {
    white-space: nowrap;
    transition: transform 0.3s;
    width: 100%;
    height: 100%;
}

.slider-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.slider-item img {
    width: 100%;
}

.slider .slider-item img:hover {
    cursor: zoom-in;
}

.slider.zoomed .slider-item img:hover {
    cursor: zoom-out;
}

.slider {
    overflow: hidden;
    transition: height 0.5s, width 0.5s, opacity 0.5s;
}

.slider.portal {
    position: absolute;
    border: solid 1px white;
    opacity: 0%;
    width: 1px;
    height: 1px;
    z-index: 1;
}

@media screen and (max-width: 1030px) {
    .slider.portal.zoomed {
        width: 750px;
        height: 376.5px;
        opacity: 100%;
    }
}

@media screen and (min-width: 1030px) and (max-width: 1520px) {
    .slider.portal.zoomed {
        width: 1000px;
        height: 502px;
        opacity: 100%;
    }
}

@media screen and (min-width: 1520px) {
    .slider.portal.zoomed {
        width: 1500px;
        height: 753px;
        opacity: 100%;
    }
}

@media screen and (min-width: 769px) {
    .card {
        width: 850px;
        height: 493px;
        background-color: #001120;
        border-radius: 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .basic-info p {
        margin: 0;
        font-size: 36px;
    }
    
    .basic-info p:first-child {
        font-size: 48px;
    }

    .socials {
        display: flex;
        width: 500px;
        height: 100px;
        align-items: center;
        justify-content: space-evenly;
    }

    .bio {
        color: white;
        text-align: center;
        width: 730px;
        position: relative;
    }
    
    .bio p {
        margin: 0;
        font-size: 18px;
        height: 260px;
    }
    
    .bio p:first-child {
        font-size: 36px;
        margin: 44px 0 0 0;
        height: 56px;
    }

    .languages {
        width: 550px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    .small-button {
        width: 23px;
        height: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: black;
    }

    .portfolio {
        color: white;
        text-align: center;
        width: 730px;
        position: relative;
    }

    .portfolio p {
        font-size: 64px;
        margin: 44px 0 43px 0;
        height: 75px;
    }
    
    .portfolio-items {
        width: 730px;
        height: 207px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin: 0 0 91px 0;
    }

    .big-button {
        display: flex;
        width: 150px;
        height: 150px;
        align-items: center;
        justify-content: center;
        background-color: black;
        position: relative;
    }

    .dst-mods {
        color: white;
        text-align: center;
        width: 730px;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
    }
    
    .dst-mods p:first-child {
        font-size: 36px;
        margin: 34px 0 27px 0;
    }
    
    .dst-mods p {
        font-size: 24px;
        margin: 34px 0 27px 0;
    }
    
    .dst-mods-items {
        display: flex;
        width: 250px;
        height: 100px;
        align-items: center;
        justify-content: space-evenly;
    }
    
    .slider {
        width: 500px;
        height: 251px;
        background-color: #000000;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .error {
        width: 400px;
        height: 400px;
        background-color: #000000;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .error p {
        margin: 0;
    }
}